'use client'
import { handleImageLeave, handleImageToggle } from '~/components/Footer/handlers';
import { v4 as uuid } from 'uuid';
import useStores from '~/store/StoreHook';
import { getConfigMC } from '~/store/Query/api';
import { useEffect, useState } from 'react';

export default function Chatbox({ isMobile }) {
  const { accountStore } = useStores();
  const [chatbotConfig, setChatbotConfig] = useState([]);

  function handleOnClick(e) {
    e.preventDefault();
    let email;
    let token;
    let user;
    const userGuest =
      accountStore.person.userGuest !== undefined ? accountStore.person.userGuest : accountStore.userGuest;

    if (chatbotConfig.AMXChatbot === false) {
      window.open(chatbotConfig.url, '_blank');
    } else {
      if (!userGuest) {
        email = accountStore.person.data && accountStore.person.data.data ? accountStore.person.data.data.logon_id : null;
        token =
          accountStore.person.data && accountStore.person.data.data && accountStore.person.data.data.token
            ? accountStore.person.data.data.token.id_token
            : null;
        user =
          accountStore.person.data && accountStore.person.data.data && accountStore.person.data.data.data
            ? accountStore.person.data.data.data
            : null;
      }
      //  Token: userGuest ? null : token,
      const json = {
        Token: null,
        Region: userGuest ? null : chatbotConfig.regionAMX,
        Category: userGuest ? 899 : parseInt(chatbotConfig.categoryAMX, 10),
        Id: uuid(),
        Date: new Date().toISOString(),
        Origin: userGuest ? 7 : 13,
        CustomerName: userGuest
          ? null
          : user && (user.first_name || user.last_name)
            ? `${user.first_name} ${user.last_name}`
            : null,
        AccountNumber: userGuest ? null : user && user.phone_1 ? user.phone_1 : null,
        Email: userGuest ? null : email,
        AppType: 'Web',
        Cedula: null,
      };

      const id = btoa(JSON.stringify(json));
      const endpoint = `${chatbotConfig.urlAMX}/Chat/Chat?id=${id}`;
      window.open(endpoint, 'chat', 'width=450, height=670, left=20, top=100');
    }
  }

  const getChatboxInfo = async () => {
    const chatbotConfig = await getConfigMC();
    if (chatbotConfig.ok) {
      setChatbotConfig(chatbotConfig.body.chatbot)
    }
  }

  useEffect(() => {
    getChatboxInfo();
  }, []);

  return (
    chatbotConfig.visible == true ?
      (<a href="#" onClick={handleOnClick}>
        {isMobile ? (
          <picture className="footer--chatbox-mobile">
            <img className="chatbox-mobile" src="/cdn/espots/media/chatbox_mobile.png" alt="image_chatbox_responsive" />
          </picture>
        ) : (
          <picture className="footer--chatbox-pc">
            <img
              src="/cdn/espots/media/icon-chat-inicio.gif"
              alt=""
              onMouseEnter={handleImageToggle}
              onMouseLeave={handleImageLeave}
            />
          </picture>
        )}
      </a>) : (<></>)
  );
}
